import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ArrowRight, DollarSign, Briefcase, Users } from "react-feather"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import WorldMap from "../components/worldmap"
//import EnablerForm from "../components/enabler-form"

const Enablers = () => (
  <>
    <Layout>
      <div id="landing-top">
        <div id="contains-map">
          <div id="map-container">
            <WorldMap />
          </div>
        </div>
        <div id="full-col">
          <div id="full-col-content">
            {/* <div className="page-icon-container">
          <Mic className="page-icon" />
        </div> */}
            <h1 id="full-header">
              Undistributed Opportunity, Squandered Potential, and Imposed
              Limitations.
            </h1>
            <h2 className="full-landing-description">
              Due to the abundance of opportunity to those to whom it is already
              presented they tend to squander and or fail to capitalize on it.
              Immigrants are typically more driven and appreciate opportunity
              more as they do not take it for granted.
            </h2>

            <h2>Beyond Borders</h2>
            <p>
              Borders are a contrived notion that only came into effect post
              world war 1, prosperity and fluid movement between cities was a
              great boon for commerce and opportunity. Practically limitless
              opportunity through free and open aggregation in hubs of
              centricity where the only constraints where time and technology.
              Now it’s politics, bureaucracy and entrenched interests that often
              do not prioritize maximizing prosperity and development.{" "}
            </p>

            <p>
              Everywhere is a go. But we’re constrained by the limitations of
              times we live in, the irony of regressive policy in the most
              progressive time in human history is constraining many, invisible
              to more, and taken as a given by most. Find a place that reduces
              these to a minimum, while a meritocratic state might not exist
              yet, our platform is the internet and with trends orienting people
              towards it as the base of innovation, the playing field is
              gradually leveling outside of policy.
            </p>

            <p>
              If you’re a founder working in the technology industry but are
              constrained by where you are located, we will do our best to level
              the playing field for you in association with awesome folks.
            </p>
          </div>
        </div>
        {/* <div id="right-col">

    <div id="enabler-actions">

      <AniLink fade to="/founders" duration={0.4}>
      <div className="lp-item-container">
        
        <div className="lp-item-header">
        <div className="item-icon-container">
        <Briefcase className="header-icon" />
        </div>
        Hire
          <div className="item-arrow">
          <ArrowRight/>

          </div>
        </div>
        <div className="item-description">
          <p>Are you a founder, team leader, or hiring manager that can offer remote or visa sponsored jobs that support relocation for unleveraged talent? If so, hire.</p>
        </div>

      </div>
      </AniLink>

      <AniLink fade to="/founders" duration={0.4}>
      <div className="lp-item-container">
        
        <div className="lp-item-header">
        <div className="item-icon-container">
        <Users className="header-icon" />
        </div>
        Mentor
          <div className="item-arrow">
          <ArrowRight/>
          </div>
        </div>
        <div className="item-description">
          <p>Are you in the tech industry and would like to assist immigrant founders in creating, building, and advancing their skills, careers, and companies?</p>
        </div>

      </div>
      </AniLink>

      <AniLink fade to="/founders" duration={0.4}>
      <div className="lp-item-container">
        
        <div className="lp-item-header">
        <div className="item-icon-container">
        <DollarSign className="header-icon" />
        </div>
        Invest
          <div className="item-arrow">
          <ArrowRight/>
          </div>
        </div>
        <div className="item-description">
          <p>Are you an angel investor or venture capitalist and would like to discover hidden unleverage talent that could potentially be the next Musk?</p>
        </div>

      </div>
      </AniLink>

      </div>
    </div> */}
      </div>
    </Layout>
  </>
)

export default Enablers
